import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { subDays, addDays } from 'date-fns';
import de from 'date-fns/locale/de';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date.scss';
import styles from './steps.module.scss';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import DateInput from '../Form/date-input';
import Button from '../Button/button';

registerLocale('de', de);

export default function DateForm({ set, date }) {
  const intl = useIntl();
  const [_date, setDate] = useState(date);
  const nextStepPath = '/get-quote/courses';

  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quoteDateHeadline" })}</FormHeadline>
      <DatePicker
        dateFormat={"d. MMMM yyyy - HH:mm"}
        timeFormat="HH:mm"
        selected={_date}
        locale="de"
        onChange={d => setDate(d)}
        excludeDates={[new Date(), subDays(new Date(), 1)]}
        showTimeSelect
        minDate={addDays(new Date(), 4)}
        customInput={<DateInput />}
      />
      <div className={styles.buttonContainer}>
        <Link to={nextStepPath}>
          <Button handleClick={() => set({date: _date})} disabled={false} elevated={false}>
            {intl.formatMessage({ id: "continueButtonLabel" })}
          </Button>
        </Link>
      </div>
    </FormContainer>
  );
}
