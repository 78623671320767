import React from 'react';
import styles from './form.module.scss';

export default function DateInput({ value, onClick }) {
  return(
    <div>
      <img style={{position: 'absolute', paddingTop: 10, paddingLeft: 10}} src={'/images/calendar.svg'} alt="calendar icon" />
      <input
        className={`${styles.input} ${styles.formControl}`}
        style={{paddingLeft: 44, width: '100%'}}
        type={'text'}
        value={value}
        onClick={onClick}
        onChange={() => {}}
      />
    </div>
  );
}
